import { Navigate, Route, Routes } from "react-router-dom";
import React, { Suspense } from "react";
import './styles/main.scss'
import { paths } from "./utils/path";



const Frontlayout = React.lazy(() =>
    import("./shared/FrontLayout/Frontlayout")
);
const Dashboard = React.lazy(() =>
    import("./components/Dashboard/Dashboard")
);
const Customer = React.lazy(() =>
    import("./components/Customer/Customer")
);
const CustomerDetail = React.lazy(() =>
    import("./components/Customer/CustomerDetail")
);
const Orders = React.lazy(() =>
    import("./components/Orders/Orders")
);
const OrderDetail = React.lazy(() =>
    import("./components/Orders/OrderDetails")
);
const Banner = React.lazy(() =>
    import("./components/Banner/Banner")
);

const Brands = React.lazy(() =>
    import("./components/Products/Brands")
);
const Categories = React.lazy(() =>
    import("./components/Products/Categories")
);
const SubCategories = React.lazy(() =>
    import("./components/Products/SubCategories")
);
const AddNewSubCategories = React.lazy(() =>
    import("./components/Products/AddNewSubCategory")
);
const AddProducts = React.lazy(() =>
    import("./components/Products/AddProducts")
);
const ProductList = React.lazy(() =>
    import("./components/Products/ProductList")
);

// notification imports
const Notification = React.lazy(() =>
    import("./components/Notification/Notification")
);

// blogs imports 
const AddBlogs = React.lazy(() =>
    import("./components/Blogs/AddBlogs")
);
const BlogList = React.lazy(() =>
    import("./components/Blogs/BlogList")
);

// faq import
const FAQ = React.lazy(() =>
    import("./components/FAQ/FAQ")
);

// coupons imports 
const Coupons = React.lazy(() =>
    import("./components/Coupons/Coupons")
);

// wallet imports 
const Wallet = React.lazy(() =>
    import("./components/Wallet/Wallet")
);

const Referrals = React.lazy(() =>
    import("./components/Referrals/Referrals")
);
const ReferralsDetails = React.lazy(() =>
    import("./components/Referrals/ReferralsDetails")
);

const SubscriptionPlan = React.lazy(() =>
    import("./components/Subscription_Plan/SubscriptionPlan")
);
const EditPlan = React.lazy(() =>
    import("./components/Subscription_Plan/EditPlan")
);

const AddCombo = React.lazy(() =>
    import("./components/Combo_Deals/AddCombo")
);
const ComboCatList = React.lazy(() =>
    import("./components/Combo_Deals/ComboProductList")
);
const AddComboCat = React.lazy(() =>
    import("./components/Combo_Deals/AddNewSubCategory")
);
const ComboProductList = React.lazy(() =>
    import("./components/Combo_Deals/ProductList")
);
const Deals = React.lazy(() =>
    import("./components/Combo_Deals/Deals")
);

// all about fitness imports 
const AllAboutFitness = React.lazy(() =>
    import("./components/AllAboutFitness/AllAboutFitness")
);

const GymGuide = React.lazy(() =>
    import("./components/Gym_Guide/GymGuide")
);

export default function Router() {
    const loading = (
        <div className="loading">
            <h1>Loading...</h1>
        </div>
    );
    return (
        <Suspense fallback={loading}>
            <Routes>
                <Route path={paths?.front?.commonPoint} element={<Frontlayout />}>
                    <Route
                        path={paths?.front?.user?.dashboard}
                        element={<Dashboard />}
                    />

                    {/* customer path  */}
                    <Route path={paths?.front?.user?.customer} element={<Customer />}>
                    </Route>
                    <Route
                        path={`${paths?.front?.user?.customer}/:id`}
                        element={<CustomerDetail />}
                    />


                    {/* orders path  */}
                    <Route
                        path={paths?.front?.user?.orders}
                        element={<Orders />}
                    />
                    <Route
                        path={`${paths?.front?.user?.orders}/:id`}
                        element={<OrderDetail />}
                    />


                    <Route
                        path={paths?.front?.user?.banner}
                        element={<Banner />}
                    />


                    {/* product routes  */}
                    <Route
                        path={`${paths?.front?.user?.products}/${paths?.front?.user?.product_list}`}
                        element={<ProductList />} />
                    <Route
                        path={`${paths?.front?.user?.products}/${paths?.front?.user?.brands}`}
                        element={<Brands />} />
                    <Route
                        path={`${paths?.front?.user?.products}/${paths?.front?.user?.categories}`}
                        element={<Categories />} />
                    <Route
                        path={`${paths?.front?.user?.products}/${paths?.front?.user?.sub_categories}`}
                        element={<SubCategories />} />
                    <Route
                        path={`${paths?.front?.user?.products}/${paths?.front?.user?.sub_categories}/${paths?.front?.user?.add_sub_categories}`}
                        element={<AddNewSubCategories />} />
                    <Route
                        path={`${paths?.front?.user?.products}/${paths?.front?.user?.sub_categories}/:id`}
                        element={<AddNewSubCategories />} />
                    <Route
                        path={`${paths?.front?.user?.products}/${paths?.front?.user?.add_products}`}
                        element={<AddProducts />} />



                    {/* notification path  */}
                    <Route
                        path={`${paths?.front?.user?.notification}`}
                        element={<Notification />} />


                    {/* blogs path  */}
                    <Route
                        path={`${paths?.front?.user?.blogs}/${paths?.front?.user?.add_blogs}`}
                        element={<AddBlogs />} />
                    <Route
                        path={`${paths?.front?.user?.blogs}/${paths?.front?.user?.blog_list}`}
                        element={<BlogList />} />

                    {/* faq path */}
                    <Route
                        path={`${paths?.front?.user?.faq}`}
                        element={<FAQ />} />

                    {/* coupons path  */}
                    <Route
                        path={`${paths?.front?.user?.coupons}`}
                        element={<Coupons />} />

                    {/* wallet path  */}
                    <Route
                        path={`${paths?.front?.user?.wallet}`}
                        element={<Wallet />} />

                    {/* referrals path */}
                    <Route
                        path={`${paths?.front?.user?.referrals}`}
                        element={<Referrals />} />
                    <Route
                        path={`${paths?.front?.user?.referrals}/:id`}
                        element={<ReferralsDetails />}
                    />



                    {/* subscription plan path  */}
                    <Route
                        path={`${paths?.front?.user?.subscription_plan}`}
                        element={<SubscriptionPlan />}
                    />
                    <Route
                        path={`${paths?.front?.user?.subscription_plan}/:id`}
                        element={<EditPlan />}
                    />
                    <Route
                        path={`${paths?.front?.user?.subscription_plan}/${paths?.front?.user?.add_subscription_plan}`}
                        element={<EditPlan />}
                    />

                    {/* combo/deals routes  */}
                    <Route
                        path={`${paths?.front?.user?.combo}/${paths?.front?.user?.add_combo_category}`}
                        element={<AddCombo />} />
                    <Route
                        path={`${paths?.front?.user?.combo}/${paths?.front?.user?.combo_sub_category_list}`}
                        element={<ComboCatList />} />
                    <Route
                        path={`${paths?.front?.user?.combo}/${paths?.front?.user?.add_combo_sub_category}`}
                        element={<AddComboCat />} />
                    {/* <Route
                        path={`${paths?.front?.user?.combo}/${paths?.front?.user?.combo_product_list}`}
                        element={<ComboProductList />} /> */}
                    <Route
                        path={`${paths?.front?.user?.combo}/${paths?.front?.user?.deals}`}
                        element={<Deals />} />

                    {/* all about fitness routes */}
                    <Route
                        path={`${paths?.front?.user?.all_about_fitness}`}
                        element={<AllAboutFitness />} />

                    {/* gym guide  */}
                    <Route
                        path={`${paths?.front?.user?.gym_guide}`}
                        element={<GymGuide />} />

                    {/* <Route
                        path={paths?.front?.user?.logout}
                        element={<CustomerDetail />}
                    /> */}


                    <Route path="/" element={<Navigate to="/dashboard" />} />
                    <Route path="*" element={<Navigate to="/dashboard" />} />
                </Route>
            </Routes>
        </Suspense>
    );
}
